import React from 'react';
// import { Link } from 'react-router-dom';
// import aboutImg from '../../assets/images/about.jpg';
import Footer from './Footer';
import Bottombar from './Bottombar';
import clientImgDefault from '../../assets/images/testimonials/asp.png';
import clientImgOne from '../../assets/images/testimonials/paresh-patel.png';
import clientImgTwo from '../../assets/images/testimonials/abhijeet-n-deore.png';
import clientImgThree from '../../assets/images/testimonials/jigar-sanghvi.png';
import clientImgFour from '../../assets/images/testimonials/prateek.png';
import clientImgFive from '../../assets/images/testimonials/asp.png';
import clientImgix from '../../assets/images/testimonials/asp.png';

class Testimonials extends React.Component {
    state = {
        isOpen: false
    }
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <React.Fragment>
                 <section className="testimonials-area ptb-80 bg-f6f6f6" id="testimonials">
                <div className="container">
                    <div className="section-title">
                        <h2>Our Customer's <span>Experience</span></h2>
                        <p>What they say</p>
                    </div>
                    
                    <div className="row">
                   
                    <div className="col-lg-4 col-md-6">
                            <div className="single-feedback">
                                <div className="client-info">
                                    <div className="client-pic">
                                        <img src={clientImgDefault} alt="client-avatar-default" />
                                    </div>

                                    <div className="client-title">
                                        <h4>Sunil Kulkarni</h4>
                                        <h5>Sholapur</h5>
                                    </div>
                                </div>
                                <p>
                                ASI has been extremely helpful in arranging the spares and are very honest in their transactions
                                </p>
                                <span><i className="fa fa-quote-left"></i></span>
                            </div>
                        </div>
                            
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feedback">
                                <div className="client-info">
                                    <div className="client-pic">
                                        <img src={clientImgOne} alt="client-avatar" />
                                    </div>

                                    <div className="client-title">
                                        <h4>Paresh Patel </h4>
                                        <h5>Ahmedabad</h5>
                                    </div>
                                </div>
                                <p>
                                Original parts and on time delivery. Highly recommended to others also. 
                                 </p>

                                <span><i className="fa fa-quote-right"></i></span>
                            </div>
                        </div>
                            
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feedback">
                                <div className="client-info">
                                    <div className="client-pic">
                                        <img src={clientImgTwo} alt="client-avatar" />
                                    </div>

                                    <div className="client-title">
                                        <h4>Abhijeet N Deore</h4>
                                        <h5>Nashik</h5>
                                    </div>
                                </div>

                                <p>
                                We choose to do business with ASI for their orignial spares. All original parts delievered. 
                                 </p>

                                <span><i className="fa fa-quote-left"></i></span>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feedback">
                                <div className="client-info">
                                    <div className="client-pic">
                                        <img src={clientImgThree} alt="client-avatar" />
                                    </div>

                                    <div className="client-title">
                                        <h4>Jigar Sanghvi</h4>
                                        <h5>Surat</h5>
                                    </div>
                                </div>

                                <p>
                                ASI  provides excellent customer service, with genuine feedback. We have been in business for a long time and look forward to more business transactions. 
                                </p>
                                    
                                <span><i className="fa fa-quote-left"></i></span>
                            </div>
                        </div>
                            
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feedback">
                                <div className="client-info">
                                    <div className="client-pic">
                                        <img src={clientImgFour} alt="client-avatar" />
                                    </div>

                                    <div className="client-title">
                                        <h4>Prateek</h4>
                                        <h5>Ahmedabad</h5>
                                    </div>
                                </div>

                                <p>
                                ASI has good reviews from market and are able to provide original spares. ASI team is helpful and forthcoming. 
                                    </p>
                                <span><i className="fa fa-quote-right"></i></span>
                            </div>
                        </div>
                            
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feedback">
                                <div className="client-info">
                                    <div className="client-pic">
                                        <img src={clientImgDefault} alt="client-avatar" />
                                    </div>

                                    <div className="client-title">
                                        <h4>Amit Borkar</h4>
                                        <h5>Ahemadnagar</h5>
                                    </div>
                                </div>

                                <p>
                                ASI is a genuine party supplying orignal spares, always accompanied by Invoice and full transparency. Fun working with ASI team always. 
                                    </p>
                                <span><i className="fa fa-quote-left"></i></span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feedback">
                                <div className="client-info">
                                    <div className="client-pic">
                                        <img src={clientImgDefault} alt="client-avatar" />
                                    </div>

                                    <div className="client-title">
                                        <h4>Ashish</h4>
                                        <h5>Surat</h5>
                                    </div>
                                </div>

                                <p>
                                They go at great lengths to make auto spares available.
                                     </p>
                                <span><i className="fa fa-quote-left"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
                <Footer />
                <Bottombar/>
            </React.Fragment>
        );
    }
}
export default Testimonials;