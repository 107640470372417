import React from 'react';
import { Link } from 'react-router-dom';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import Footer from './Footer';
import Bottombar from './Bottombar';
class Faq extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <React.Fragment>
            <section className="faq-area ptb-80">
                <div className="container">
                    <div className="section-title">
                        <h2>Frequently Asked <span>Question</span></h2>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                    </div>

                    <div className="row">
                        <div className="col-lg-7 col-md-12">
                            <div className="accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                                <Accordion>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                When was New Age Vehicles Pvt Ltd (Auto Spares India) incorporated?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                New Age Vehicles Pvt Ltd was incorporated in December, 2015.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Where is Auto Spares India headquarter?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Auto Spares India has Head Quarter in New Delhi, with regional offices in Maharashtra and Gujarat
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                What does Auto Spares India do?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Autos Spares India deals in genuine spare parts for passenger cars
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                            What are Auto Spares India’s products?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            We provide any and all parts, ranging from car body parts, car engine, dashboards, etc, that are used in your vehicle. 
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                            What is the best time to contact Auto Spares India’s support team?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Our support team is available 10:00 AM to 6:00 PM Monday to Saturday. You can contact us on the Support Mobile No.: +91-8802607799
                                            </p>
                                            <p>Alternatively, you can contact us on Whatsapp No.: +91-8802607799 and Email Id: tkumar@autosparesindia.in / akumar@autosparesindia.in 24*7. We will get back to you as soon as possible.
</p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                            The website asks me to put my details like email id, phone number, etc in Contact Us? Is it safe to give this information?

                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            We value the privacy of our customers, and ensure information usage and data security as per the <Link to="/privacy-policy" title="Read More" className="link-btn">Privacy Policy</Link>. 
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                            What are the terms and conditions of using the website?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            The terms and conditions of using the website can be accessed by clicking <Link to="/terms-and-conditions" title="Read More" className="link-btn">this link</Link>. 
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>


                                </Accordion>
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-12">
                            <div className="image"></div>
                        </div>
                    </div>
                </div>
                
            </section>
            <Footer />
            <Bottombar/>
            </React.Fragment>
        );
    }
}

export default Faq;