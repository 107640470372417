import React from 'react';
import { Link } from 'react-router-dom';
// import aboutImg from '../../assets/images/about.jpg';
import Footer from './Footer';
import Bottombar from './Bottombar';
import OwlCarousel from 'react-owl-carousel3';

const options = {
    loop: true,
    autoplay:true,
    nav: false,
    mouseDrag: true,
    autoplayHoverPause: false,
    autoplayTimeout:2000,
    responsiveClass: true,
    dots: true,
    navText: [
        "<i class='fa fa-angle-left'></i>",
        "<i class='fa fa-angle-right'></i>"
    ],
    responsive:{
        0:{
            items:2,
        },
        768:{
            items:4,
        },
        1200:{
            items:6,
        }
    }
}
class HappyClients extends React.Component {
    state = {
        isOpen: false
    }
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <React.Fragment>
                <section id="about" className="about-area ptb-80">
                    {/* <section id="about" className="about-area ptb-80 bg-f6f6f6"></section> */}
                    <div className="partner-area ptb-40 ">
                <div className="container">
                    <div className="row">
                        <OwlCarousel 
                            className="partner-slider owl-carousel owl-theme"
                            {...options}
                        >
                            <div className="col-lg-12 col-md-12">
                                <div className="item">
                                    <Link to="#"><img src={require('../../assets/images/audi.png')} alt="audi" /></Link>
                                </div>
                            </div>
                            
                            <div className="col-lg-12 col-md-12">
                                <div className="item">
                                    <Link to="#"><img src={require('../../assets/images/bmw.png')} alt="bmw" /></Link>
                                </div>
                            </div>
                            
                            <div className="col-lg-12 col-md-12">
                                <div className="item">
                                    <Link to="#"><img src={require('../../assets/images/chevrolet.png')} alt="chevrolet" /></Link>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="item">
                                    <Link to="#"><img src={require('../../assets/images/datsun.png')} alt="datsun" /></Link>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="item">
                                    <Link to="#"><img src={require('../../assets/images/fiat.png')} alt="fiat" /></Link>
                                </div>
                            </div>
                            
                            <div className="col-lg-12 col-md-12">
                                <div className="item">
                                    <Link to="#"><img src={require('../../assets/images/ford.png')} alt="ford" /></Link>
                                </div>
                            </div>
                            
                            <div className="col-lg-12 col-md-12">
                                <div className="item">
                                    <Link to="#"><img src={require('../../assets/images/hindustan-motors.png')} alt="hindustan-motors" /></Link>
                                </div>
                            </div>
                            
                            <div className="col-lg-12 col-md-12">
                                <div className="item">
                                    <Link to="#"><img src={require('../../assets/images/honda.png')} alt="honda" /></Link>
                                </div>
                            </div>
                            
                            <div className="col-lg-12 col-md-12">
                                <div className="item">
                                    <Link to="#"><img src={require('../../assets/images/hyundai.png')} alt="hyundai" /></Link>
                                </div>
                            </div>
                            
                            <div className="col-lg-12 col-md-12">
                                <div className="item">
                                    <Link to="#"><img src={require('../../assets/images/kia.png')} alt="kia" /></Link>
                                </div>
                            </div>
                            
                            <div className="col-lg-12 col-md-12">
                                <div className="item">
                                    <Link to="#"><img src={require('../../assets/images/land-rover-range-rover.png')} alt="land-rover-range-rover" /></Link>
                                </div>
                            </div>
                            
                            <div className="col-lg-12 col-md-12">
                                <div className="item">
                                    <Link to="#"><img src={require('../../assets/images/mahindra.png')} alt="mahindra" /></Link>
                                </div>
                            </div>
                            
                            <div className="col-lg-12 col-md-12">
                                <div className="item">
                                    <Link to="#"><img src={require('../../assets/images/maruti-suzuki.png')} alt="maruti-suzuki" /></Link>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="item">
                                    <Link to="#"><img src={require('../../assets/images/mercedes.png')} alt="mercedes" /></Link>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="item">
                                    <Link to="#"><img src={require('../../assets/images/mitsubishi.png')} alt="mitsubishi" /></Link>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="item">
                                    <Link to="#"><img src={require('../../assets/images/motor-mg.png')} alt="motor-mg" /></Link>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="item">
                                    <Link to="#"><img src={require('../../assets/images/nissan.png')} alt="nissan" /></Link>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="item">
                                    <Link to="#"><img src={require('../../assets/images/renault.png')} alt="renault" /></Link>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="item">
                                    <Link to="#"><img src={require('../../assets/images/skoda.png')} alt="skoda" /></Link>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="item">
                                    <Link to="#"><img src={require('../../assets/images/tata-motors.png')} alt="tata-motors" /></Link>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="item">
                                    <Link to="#"><img src={require('../../assets/images/volkswagen.png')} alt="volkswagen" /></Link>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                    <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-addax">
                                    {/* <div className="section-title">
                                        <h3>Introduction</h3>
                                    </div> */}
                                    <p>All logos are proprietary to their owners and are used for reference purposes only. NAVPL claims no ownership to any and all logos.</p>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
                </section>
                <Footer />
                <Bottombar/>
            </React.Fragment>
        );
    }
}
export default HappyClients;