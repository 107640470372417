import React from 'react';
// import { Link } from 'react-router-dom';
 
class Bottombar extends React.Component {
    render(){
        return ( 
            <div className="bottombar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <p><i className="fa fa-copyright"></i> 2020 New Age Vehicles Pvt. Ltd.| All Rights Reserved.</p>
                        </div>
                        <div className="col-lg-6 col-md-6"></div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Bottombar;