import React from 'react';
// import { Link } from 'react-router-dom';
// import aboutImg from '../../assets/images/about.jpg';
import Footer from './Footer';

class Disclaimer extends React.Component {
    state = {
        isOpen: false
    }


    render() {
        return (
            <React.Fragment>
                <section id="about" className="about-area ptb-80">
                    {/* <section id="about" className="about-area ptb-80 bg-f6f6f6"></section> */}
                    <div className="container">
                        <div className="section-title">
                            <h2><span>Disclaimer</span></h2>
                            <p></p>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-addax">
                                    {/* <div className="section-title">
                                        <h3>Introduction</h3>
                                    </div> */}
                                    <p>The disclaimer 'All logos are proprietary to their owners and are used for reference purposes only. NAVPL claims no ownership to any and all logos. ' should come below the car brands logo scroll.</p>
                                </div>
                            </div>
                        </div>
                        {/* break */}
                        {/* <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-addax">
                                    <div className="section-title">
                                        <h3></h3>
                                    </div>
                                    <p> All logos are proprietary to their owners and are used for reference purposes only. NAVPL claims no ownership to any and all logos. </p>
                                </div>
                            </div>
                        </div> */}
                        {/* break */}

                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}
export default Disclaimer;