import React from 'react';
// import { Link } from 'react-router-dom';
import aboutImg from '../../assets/images/faq-img.jpg';
import Footer from './Footer';
import Bottombar from './Bottombar';


class AboutUs extends React.Component {
    state = {
        isOpen: false
    }
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <React.Fragment>
                <section id="about" className="about-area ptb-80">
                    {/* <section id="about" className="about-area ptb-80 bg-f6f6f6"></section> */}
                    <div className="container">
                        <div className="section-title">
                            <h2>About Auto Spares India</h2>
                            <p>AutoSpares India is a wholly owned and sole initiative of New Age Vehicles Private Limited.</p>
                            <p></p>
                        </div>

                        <div className="row">
                            <div className="col-lg-7 col-md-12">
                                <div className="about-addax">
                                    <p>India’s aftermarket for Transport Vehicles industry and Industrial Equipment industry is both unorganized and fragmented with genuine spare parts availability being one of the major problems faced by Indian vehicle / equipment owners as well as the aftermarket services enterprises like garages, workshops etc.  The genuine spares availability, particularly in tier-2 and tier-3 cities, gets more exacerbated with spares delivery lead times ranging from 7 to 21 days. New Age Vehicles is the first start up to focus on this issue.
</p>
                                    <p>
                                        Our vision is to be India’s most customer-centric aftermarket spares and accessories company using mix of technology, logistics and ease-of-use features to make genuine spares available to customers promptly. We will be the destination where customers can come to find just about any spare they wish to buy for their vehicle / equipment with the ease and convenience of online shopping. We aim at building platform for the spare parts industry where there is transparency, ease, and equal opportunity for all its stakeholders. This is done by providing our customers with variety of spare parts with best possible prices as we source the products straight from the manufacturers and suppliers.

</p>
                                    {/* <div className="section-title">
                                    <h2>Why <span> AutoSpares India</span></h2>
                                </div> */}

                                    {/* <ul className="pull-left">
                                    <li><i className="fa fa-check"></i>Wide selection of auto spares parts</li>
                                    <li><i className="fa fa-check"></i>On-time delivery</li>
                                    <li><i className="fa fa-check"></i>Personal Accountability</li>
                                    <li><i className="fa fa-check"></i>Quality products with transparent pricing</li>
                                </ul>

                               

                                <Link to="#" className="btn btn-primary">Read More</Link> */}
                                </div>
                            </div>

                            <div className="col-lg-5 col-md-12">
                                <div className="about-video">
                                    <img src={aboutImg} alt="about" />
                                    <div className="video-btn">


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Bottombar/>
            </React.Fragment>
        );
    }
}

export default AboutUs;