import React from 'react';
//  import { Link } from 'react-router-dom';
// import aboutImg from '../../assets/images/faq-img.jpg';
import Footer from './Footer';
import Bottombar from './Bottombar';
class MissionVision extends React.Component {
    state = {
        isOpen: false
    }

    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <React.Fragment>
                <section className="how-work ptb-80">
                <div className="container">
                    <div className="section-title">
                        <h2>Mission, Vision <span>& Core Values</span></h2>
                        {/* <p>All parts are sorted by producers' information and filtered by customers’ choice. Quick and competent service. If any problems occur, autospareindia.in will put its best foot forward to solve issues.</p> */}
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-md-12 offset-md-12 offset-lg-0 mtb-10">
                            <div className="single-how-work">
                                <h3>01.</h3>
                                <h4>Mission</h4>
                                <p>The mission of our organization is to provide extensive range of quality spares and accessories for transport vehicles and industrial machines in India. Our aim is to build up capacity and capability in spares, accessories and services that can cater to our customer requirements on time, every time and become a strategically reliable partner.
</p>
                                {/* <p><Link to="/mission-details" title="Read More" className="link-btn">Read More</Link></p> */}
                                {/* <a title="Read More" class="link-btn" href="/mission-details"><i class="fa fa-arrow-right"></i></a> */}
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 offset-md-12 offset-lg-0 mtb-10">
                            <div className="single-how-work">
                                <h3>02.</h3>
                                <h4>Vision</h4>
                                <p>To be India’s most customer-centric technology savvy and logistics driven spare parts and accessories company enhancing customer satisfaction first time and every time.</p>
                                {/* <a title="Read More" class="link-btn" href="/mission-details"><i class="fa fa-arrow-right"></i></a> */}
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 offset-md-12 offset-lg-0 mtb-10">
                            <div className="single-how-work">
                                <h3>03.</h3>
                                <h4>Core Values</h4>
                                <p>Customer Commitment – We are customer focussed and are in for long term relationships
</p>
                                <p>Integrity – We do what is right always</p>
                                <p>Respect for People – Respect for suppliers, customers, employees and stakeholders is key to success
</p>
                                <p>Data Driven – We constantly strive to build tools, abilities and culture that acts on data and not on perceptions.
</p>
                                <p>Winning Attitude – We believe in creating win-win situations for all stakeholders and have perseverance, humbleness, and a willingness to learn from mistakes
</p>
                                <p>
                                    Personal Accountability – Each associate takes personal responsibility for the quality and timeliness of all work ensuring high levels of customer satisfaction

</p>
                                {/* <a title="Read More" class="link-btn" href="/mission-details"><i class="fa fa-arrow-right"></i></a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
                <Footer />
                <Bottombar/>
            </React.Fragment>
        );
    }
}

export default MissionVision;