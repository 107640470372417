import '../../../node_modules/bootstrap/scss/bootstrap.scss';
import '../../assets/css/font-awesome.min.css';
import '../../assets/css/animate.css';
import '../../assets/css/style.scss';
import '../../assets/css/responsive.scss';
import React from 'react';
// import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link, withRouter  } from 'react-router-dom';
 
class Topbar extends React.Component {
    render(){        
        return (		
            <div id="topbar" className="topbar">			
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 tp-notify">
							<p><img src={require('../../assets/images/notification.png')} alt="notification" />  Reliable services assuring customer satisfaction</p>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 tp-contact">
							<Link to="#"><img src={require('../../assets/images/call.png')} alt="call" /> +91-8802607799</Link>
						</div>
					</div>
                </div>
            </div>
        );
    }
} 
export default withRouter(Topbar);