import React from 'react';
// import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import { Link } from 'react-router-dom';
import aboutImg from '../../assets/images/about.jpg';
 
class About extends React.Component {
    state = {
        isOpen: false
    }

    openModal = () => {
        this.setState({isOpen: true})
    }
    render(){
        return (
            <section id="about" className="about-area ptb-80">
                {/* <section id="about" className="about-area ptb-80 bg-f6f6f6"></section> */}
                <div className="container">
                    <div className="section-title">
                        <h2>About <span>Auto Spares India</span></h2>
                        <p>AutoSpares India is a wholly owned and sole initiative of New Age Vehicles Private Limited.</p>
                        <p>India’s aftermarket for Transport Vehicles industry and Industrial Equipment industry is both unorganized and fragmented with genuine spare parts availability being one of the major problems faced by Indian vehicle / equipment owners as well as the aftermarket services enterprises like garages, workshops etc. The genuine spares availability, particularly in tier-2 and tier-3 cities, gets more exacerbated with spares delivery lead times ranging from 7 to 21 days. New Age Vehicles is the first start up to focus on this issue.</p>
                        <p>Our vision is to be India’s most customer-centric aftermarket spares and accessories company using mix of technology, logistics and ease-of-use features to make genuine spares available to customers promptly. We are the destination where customers can come to find just about any spare they wish to buy for their vehicle / equipment with complete ease. We aim at building platform for the spare parts industry where there is transparency, ease, and equal opportunity for all its stakeholders. This is done by providing our customers with variety of spare parts with best possible prices as we source the products straight from the manufacturers and suppliers.</p>
                    <p><Link to="/why-auto-spare-india" className="btn btn-primary">Explore Now</Link></p>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-addax">
                                <div className="section-title">
                                    <h2>Why <span> AutoSpares India</span></h2>
                                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                                </div>
                                <h6><i className="fa fa-lemon-o"></i> Wide selection of auto spares parts</h6>
                                <p>We provide spare parts for all car brands and models. </p>
                                <h6><i className="fa fa-lemon-o"></i> On-time delivery</h6>
                                <p>We are committed to on-time delivery and have transparent communication in case of delays with proactive approach to resolve the delays.</p>
                                <h6><i className="fa fa-lemon-o"></i> Personal Accountability</h6>
                                <p>Each associate takes personal responsibility for the quality and timeliness of all work ensuring high levels of customer satisfaction.</p>
                                <h6><i className="fa fa-lemon-o"></i> Quality products with transparent pricing</h6>
                                {/* <ul className="pull-left">
                                    <li><i className="fa fa-check"></i>Wide selection of auto spares parts</li>
                                    <li><i className="fa fa-check"></i>On-time delivery</li>
                                    <li><i className="fa fa-check"></i>Personal Accountability</li>
                                    <li><i className="fa fa-check"></i>Quality products with transparent pricing</li>
                                    <li><Link to="/why-auto-spare-india" className="btn btn-primary">Explore Now</Link></li>
                                </ul> */}

                                {/* <ul>
                                    <li><i className="fa fa-check"></i>Personal Accountability</li>
                                    <li><i className="fa fa-check"></i>Quality products with transparent pricing</li>
                                    <li><i className="fa fa-check"></i>Customer Service</li> 
                                </ul> */}

                                
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-md-12">
                            <div className="about-video">
                                <img src={aboutImg} alt="about" />
                                {/* <div className="video-btn">
                                    <Link 
                                        onClick={e => {e.preventDefault(); this.openModal()}}
                                        to="#" 
                                        className="popup-youtube"
                                    >
                                        <i className="fa fa-play"></i>
                                    </Link>
                                    <ModalVideo 
                                        channel='youtube' 
                                        isOpen={this.state.isOpen} 
                                        videoId='bk7McNUjWgw' 
                                        onClose={() => this.setState({isOpen: false})} 
                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default About;