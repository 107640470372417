import React from 'react';
import { Link } from 'react-router-dom';
// import aboutImg from '../../assets/images/about.jpg';
import Footer from './Footer';
import Bottombar from './Bottombar';
import GoogleMapReact from 'google-map-react';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
// import ReCAPTCHA from "react-google-recaptcha";
import Recaptcha from 'react-google-invisible-recaptcha';
import 'isomorphic-fetch';
const CustomComponent = ({ text }) => <div><img src="http://ruralshores.com/assets/marker-icon.png" alt="map" /></div>;

    class ContactUs extends React.Component {
        static defaultProps = {
            center: {
                lat: 28.5490509,
                lng: 77.2116255
            },
            zoom: 11
        };
    
        state = {
            isOpen: false,
            submitting: false,
            submitted: false,
            buttonState: '',
            formFields: {
                name: '',
                email: '',
                phone: '',
                text: '',
            isCaptchaValid: false,
            isErrorShown: false,
            isFormValid: false
            }
        };
    
        onSubmit = (e) => {
            this.recaptcha.execute();
            e.preventDefault();
            const data = this.state.formFields;
            fetch('/api/contact', {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => {
                if (res.status === 200) {
                    this.setState({ submitted: true })
                }
                let formFields = Object.assign({}, this.state.formFields);
                formFields.name = '';
                formFields.email = '';
                formFields.phone = '';
                formFields.text = '';
                this.setState({formFields});
            });
        }
    
        nameChangeHandler = (e) => {
            let formFields = Object.assign({}, this.state.formFields);
            formFields.name = e.target.value;
            this.setState({formFields});
        }
    
        emailChangeHandler = (e) => {
            let formFields = Object.assign({}, this.state.formFields);
            formFields.email = e.target.value;
            this.setState({formFields});
        }
    
        phoneChangeHandler = (e) => {
            let formFields = Object.assign({}, this.state.formFields);
            formFields.phone = e.target.value;
            this.setState({formFields});
        }
    
        textChangeHandler = (e) => {
            let formFields = Object.assign({}, this.state.formFields);
            formFields.text = e.target.value;
            this.setState({formFields});
        }
    
        onHideSuccess = () => {
            this.setState({submitted: false});
        }
    // Show message in console when reCaptcha plugin is loaded
    onCaptchaLoad = () => {
        console.log('Captcha loaded')
    }
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    // Update state after reCaptcha validates visitor
    onCaptchaVerify = (response) => {
        this.setState({
            isCaptchaValid: true
        })
    }
        successMessage = () => {
            if (this.state.submitted){
                return (
                    <div 
                        className="alert alert-success alert-dismissible fade show" 
                        style={{ marginTop: '14px' }}
                    >
                        <strong>Thank you!</strong> We will revert shortly.
                        <button 
                            type="button" 
                            className="close"
                            onClick={this.onHideSuccess}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                );
            }
        }
        sendMessage = () => {
            this.recaptcha.execute();
        }
        onResolved = () => {
            this.setState({messageSent: true})
            // Process Data //
            console.log(this.state);
        }
    render(){
        return (
            <React.Fragment>
             <section id="contact" className="contact-area ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h4>Get in Touch</h4>
                        <h2>Contact <span>Us</span></h2>
                        <p>WE WOULD LIKE TO HEAR FROM YOU!</p>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div id="map">
                            <iframe frameBorder="0" style={{ width: "100%", height: "100%"}} 
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14018.758035593739!2d77.2138142!3d28.5490509!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa5eaf6e44647329f!2sNew%20Age%20Vehicles%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1584512906395!5m2!1sen!2sin">
            </iframe>
                                {/* <div style={{ height: '100%', width: '100%' }}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: 'AIzaSyDTWAxQ7Z4GNC28ljvgSDBAmTiZ-eYiGog' }}
                                        defaultCenter={this.props.center}
                                        defaultZoom={this.props.zoom}
                                    >
                                        <CustomComponent
                                            lat={28.5492644}
                                            lng={77.2119295}
                                            text="New Age Vehicles Pvt. Ltd."
                                        />
                                    </GoogleMapReact>
                                </div> */}
                            </div>
                            
                            <div className="contact-info">
                                <ul>
                                    <li>
                                        <i className="fa fa-map-marker"></i> 204, 3rd Floor, Shahpur Jat, New Delhi – 110049.
                                    </li>
                                    <li>
                                        <i className="fa fa-envelope"></i> 
                                        <Link to="#">info@autosparesindia.in</Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-phone"></i> 
                                        <Link to="#">+91-8802607799</Link>
                                    </li>
                                    {/* <li>
                                        <i className="fa fa-fax"></i> 
                                        <Link to="#">617-241-60055</Link>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-md-12">
                            <div className="contact-form">
                                <h4>Stay Connected</h4>
                                <form id="contactForm" onSubmit={this.onSubmit}>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="name">Name</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    placeholder="Name..."
                                                    name="name" 
                                                    id="name" 
                                                    required={true}
                                                    data-error="Please enter your name" 
                                                    value={this.state.formFields.name}
                                                    onChange={this.nameChangeHandler}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="email">Email</label>
                                                <input 
                                                    type="email" 
                                                    className="form-control" 
                                                    placeholder="Email..."
                                                    name="email" 
                                                    id="email" 
                                                    required={true}
                                                    data-error="Please enter your email" 
                                                    value={this.state.formFields.email}
                                                    onChange={this.emailChangeHandler}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="number">Mobile Number</label>
                                                <input 
                                                    type="number" 
                                                    placeholder="Mobile Number"
                                                    className="form-control" 
                                                    name="number" 
                                                    id="number" 
                                                    required={true}
                                                    data-error="Please enter your number" 
                                                    value={this.state.formFields.phone}
                                                    onChange={this.phoneChangeHandler}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="message">Message</label>
                                                <textarea 
                                                    name="message" 
                                                    className="form-control" 
                                                    id="message" 
                                                    cols="30" 
                                                    rows="2" 
                                                    required={true}
                                                    data-error="Write your message" 
                                                    value={this.state.formFields.text}
                                                    onChange={this.textChangeHandler}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                        <Recaptcha
                        ref={ ref => this.recaptcha = ref }
                        sitekey="6LdUmKUZAAAAAAM2idwPCCTjDM8YrM_jK-0ohNtU"
                        onResolved={ this.onResolved }
                    />
                                            {/* <ReCAPTCHA
                                                onloadCallback={this.onCaptchaLoad}
                                                sitekey="6LdEnaMZAAAAAN84GoCsuXLjl5fCQSF8iT-LQz9N"
                                                render="explicit"
                                                verifyCallback={this.onCaptchaVerify}
                                            /> */}
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                        <button type="submit" onClick={()=>{ fetch("https://autosparesindia.in/smtpform.php", {
    method: "POST", body: JSON.stringify(this.state)})}} className="btn btn-primary">Send Message</button>
                                            {this.successMessage()}
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <Bottombar/>
            </React.Fragment>
        );
    }
}
 
export default ContactUs;