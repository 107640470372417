import React from 'react';
// import { Link } from 'react-router-dom';
// import aboutImg from '../../assets/images/faq-img.jpg';
import Footer from './Footer';
import Bottombar from './Bottombar';
class WhyAutoSpareIndia extends React.Component {
    state = {
        isOpen: false
    }

    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <React.Fragment>
                <section id="about" className="about-area ptb-80">
                    {/* <section id="about" className="about-area ptb-80 bg-f6f6f6"></section> */}
                    <div className="container">
                        <div className="section-title">
                            <h2>What <span>We Do</span></h2>
                            <p>Straight from the manufacturers and suppliers.</p>
                        </div>

                        <div className="row">
                            {/* <div className="col-lg-12 col-md-12">
                                <div className="about-addax">
                                    <div className="section-title">
                                        <h2>Why <span> AutoSpares India</span></h2>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    </div>
                                    <ul className="pull-left">
                                        <li><i className="fa fa-check"></i>Wide selection of auto spares parts</li>
                                        <li><i className="fa fa-check"></i>On-time delivery</li>                                        
                                    </ul>
                                    <ul className="pull-right">                                        
                                        <li><i className="fa fa-check"></i>Personal Accountability</li>
                                        <li><i className="fa fa-check"></i>Quality products with transparent pricing</li>
                                    </ul>
                                </div>
                            </div> */}
                            <div className="col-lg-12 col-md-12">
                                <div className="about-addax">
                                    <p>NAVPL provides a wide selection of (genuine) auto spare parts according to your car brand and model, and your requirement. We assist customers in the discovery and availability/sourcing of spare parts for any car brand and model.</p>
                                    {/* <h4>Our range of spare parts cover all parts of a car:</h4>               */}
                                    <div class="section-title"><h3>Our range of spare parts cover all parts of a car:</h3></div>  
                                    <h6><i className="fa fa-eercast"></i> Car body and main part:</h6>                   
                                    <p>Body components, including trim, Doors, Windows</p>
                                    <h6><i className="fa fa-eercast"></i> Low voltage/auxiliary electrical system and electronics:</h6> 
                                    <p>Audio/video devices, Cameras, Low voltage electrical supply system, Gauges and meters, Ignition system, Lighting and signalling system, Sensors, Starting system, Electrical switches, Wiring harnesses, Miscellaneous</p>
                                    <h6><i className="fa fa-eercast"></i> Interior:</h6>
                                    <p>Floor components and parts, Other components, Car seat, Arm Rest, Bench seat, Bucket seat, Children and baby car seat, Fastener, Headrest, Seat belt, Seat bracket, Seat cover, Seat track, Other seat components, Back seat, Front seat</p>
                                    <h6><i className="fa fa-eercast"></i> Power-train and chassis:</h6> 
                                    <p>Braking system, Electrified powertrain components, Engine components and parts, Engine cooling system, Engine oil systems, Exhaust system, Fuel supply system, Suspension and steering systems, Transmission system</p>
                                    <h6><i className="fa fa-eercast"></i> Miscellaneous auto parts:</h6> 
                                    <p>Air conditioning system (A/C), Bearings, Hose, Other miscellaneous parts like Air Bag, Cables, Dashboard, Gaskets, Fasteners etc </p>
                                    <p>We have a keen focus on hassle-free delivery of the parts to our customers. We provide various delivery options based on spares part type, sourcing location and urgency of delivery. We have options for both Ex-works (EXW) and Delivered-At-Place (DAP).</p>
                                    <p>Last but not the least, we are entirely service focussed. Our team spares no effort to assist and guide customers from discovery till delivery.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Bottombar/>
            </React.Fragment>
        );
    }
}
export default WhyAutoSpareIndia;