import React from 'react';
import { Link } from 'react-router-dom';
 
class Footer extends React.Component {
    render(){
        return (
            <footer className="footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-3">
                        <img className="footerlogo" src={require('../../assets/images/logo.png')} alt="logo" />
                        <p>AutoSpares India is a wholly owned and sole initiative of New Age Vehicles Private Limited.</p>
                            {/* <h4><Link to="/"><span>a</span>ddax</Link></h4> */}                                                        
                        </div>
						<div className="col-lg-3 col-md-3 bottomnav">
                            <h2><span>C</span>ompany</h2>
                            <div className="bar"></div>
                            <ul>
                                <li><i className="fa fa-long-arrow-right"></i><Link to="/about-us">About us</Link></li>
                                <li><i className="fa fa-long-arrow-right"></i><Link to="/contact-us">contact us</Link></li>
                                <li><i className="fa fa-long-arrow-right"></i><Link to="/testimonials">Testimonials</Link></li>
                                <li><i className="fa fa-long-arrow-right"></i><Link to="/faqs">FAQs</Link></li>
                                <li><i className="fa fa-long-arrow-right"></i><Link to="/car-brands-served">Car Brands Served</Link></li>
                                                                
                            </ul>      
                        </div>
						<div className="col-lg-3 col-md-3 bottomnav">
                            <h2><span>P</span>olicy</h2>
                            <div className="bar"></div>
                            <ul>
                                
                                {/* <li><i className="fa fa-long-arrow-right"></i><Link to="#">Blogs</Link></li>                                 */}
                                <li><i className="fa fa-long-arrow-right"></i><Link to="/privacy-policy">Privacy Policy</Link></li>
                                <li><i className="fa fa-long-arrow-right"></i><Link to="/tnc">Terms & Conditions</Link></li>
                                {/* <li><i className="fa fa-long-arrow-right"></i><Link to="/disclaimer">Disclaimer</Link></li> */}
                                {/* <li><i className="fa fa-long-arrow-right"></i><Link to="/our-client">OurClients</Link></li> */}
                            </ul>                        
                        </div>
						<div className="col-lg-3 col-md-3">
                            <h2><span>C</span>ontact Us</h2>
                            <div className="bar"></div>
                            <p><span>204, 3rd Floor, Shahpur Jat, New Delhi – 110049.</span></p>
                            <p><span>info@autosparesindia.in</span></p>
                            <p><span>+91-8802607799</span></p>
                            {/* <ul>
                                <li><Link to="#" className="fa fa-facebook"></Link></li>
                                <li><Link to="#" className="fa fa-twitter"></Link></li>
                                <li><Link to="#" className="fa fa-skype"></Link></li>
                                <li><Link to="#" className="fa fa-instagram"></Link></li>
                                <li><Link to="#" className="fa fa-linkedin"></Link></li>
                            </ul>                             */}
                        </div>
                    </div>
                </div>				
            </footer>
        );
    }
}
 
export default Footer;