import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Topbar from './components/Navigation/Topbar';
import Navigation from './components/Navigation/Navigation';
import Preloader from './components/Common/Preloader';
import HomeOne from '../src/components/Pages/HomeOne';
// import HomeTwo from '../src/components/Pages/HomeTwo';
// import HomeThree from '../src/components/Pages/HomeThree';
// import HomeFour from '../src/components/Pages/HomeFour';
// import HomeFive from './components/Pages/HomeFive';
// import HomeSix from './components/Pages/HomeSix';
// import HomeSeven from './components/Pages/HomeSeven';
// import HomeEight from './components/Pages/HomeEight';
// import HomeNine from './components/Pages/HomeNine';
// import HomeTen from './components/Pages/HomeTen';
// import HomeEleven from './components/Pages/HomeEleven';
// import HomeTwelve from './components/Pages/HomeTwelve';
import Blog from './components/Pages/Blog';
import BlogDetails from './components/Pages/BlogDetails';
import PrivacyPolicy from './components/Common/Privacy-policy';
import Tnc from './components/Common/tnc';
import Testimonials from './components/Common/Testimonials';
import HappyClients from './components/Common/Happy-clients';
import ContactUs from './components/Common/Contact-us';
import AboutUs from './components/Common/About-us';
import Faq from './components/Common/Faq';
import OurClient from './components/Common/Our-client';
import MissionVision from './components/Common/MissionVision';
import WhyAutoSpareIndia from './components/Common/Why-Auto-Spare-India';
import Disclaimer from './components/Common/Disclaimer';

class App extends React.Component {

    state = {
        loading: true
    };

    componentDidMount(){
        this.demoAsyncCall().then(() => this.setState({ loading: false }));
    }

    demoAsyncCall = () => {
        return new Promise((resolve) => setTimeout(() => resolve(), 2000));
    }

    hashLinkScroll = () => {
        const { hash } = window.location;
        if (hash !== '') {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) element.scrollIntoView();
            }, 0);
        }
    }

    render() {
        return (
            <Router onUpdate={this.hashLinkScroll}>
                <React.Fragment>
                    {this.state.loading ? <Preloader /> : ''}
					 <Topbar />
                    <Navigation />
                    <Route path="/" exact component={HomeOne} />
                    {/* <Route path="/home-two" exact component={HomeTwo} />
                    <Route path="/home-three" exact component={HomeThree} />
                    <Route path="/home-four" exact component={HomeFour} />
                    <Route path="/home-five" exact component={HomeFive} />
                    <Route path="/home-six" exact component={HomeSix} />
                    <Route path="/home-seven" exact component={HomeSeven} />
                    <Route path="/home-eight" exact component={HomeEight} />
                    <Route path="/home-nine" exact component={HomeNine} />
                    <Route path="/home-ten" exact component={HomeTen} />
                    <Route path="/home-eleven" exact component={HomeEleven} />
                    <Route path="/home-twelve" exact component={HomeTwelve} /> */}
                    <Route path="/blog" exact component={Blog} />
                    <Route path="/privacy-policy" exact component={PrivacyPolicy} />
                    <Route path="/tnc" exact component={Tnc} />
                    <Route path="/testimonials" exact component={Testimonials} />
                    {/* <Route path="/happy-clients" exact component={HappyClients} /> */}
                    <Route path="/car-brands-served" exact component={HappyClients} />
                    <Route path="/contact-us" exact component={ContactUs} />
                    <Route path="/about-us" exact component={AboutUs} />
                    <Route path="/faqs" exact component={Faq} />
                    <Route path="/our-client" exact component={OurClient} />
                    <Route path="/blog-details" exact component={BlogDetails} />
                    <Route path="/mission-vision" exact component={MissionVision} />
                    <Route path="/why-auto-spare-india" exact component={WhyAutoSpareIndia} />
                    <Route path="/disclaimer" exact component={Disclaimer} />
                </React.Fragment>
            </Router>
        );
    }
}

export default App;
