import React from 'react'
import MainBanner from '../PageBanners/MainBannerTwo';
// import MainBanner from '../PageBanners/MainBannerOne';
// import Welcome from '../Common/Welcome';
import About from '../Common/About';
// import Strategy from '../Common/Strategy';
// import WhoWeAre from '../Common/WhoWeAre';
// import Ctr from '../Common/Ctr';
// import Skill from '../Common/Skill';
import Funfact from '../Common/Funfact';
// import Team from '../Common/Team';
import HowWeWork from '../Common/HowWeWork';
// import Services from '../Common/Services';
import WhyWeDifferent from '../Common/WhyWeDifferent';
// import Work from '../Common/Work';
// import Price from '../Common/Price';
import Faqs from '../Common/Faqs';
import Testimonial from '../Common/Testimonial';
// import Blog from '../Common/Blog';
import Partner from '../Common/Partner';
// import Subscribe from '../Common/Subscribe';
import Contact from '../Common/Contact';
import Footer from '../Common/Footer';
import Bottombar from '../Common/Bottombar';
import GoTop from '../Common/GoTop';
 
class HomeOne extends React.Component {
    render(){
        return (
            <React.Fragment>
                {/* Main Banner */}
                <MainBanner />
                <About />
                <WhyWeDifferent />
                {/* Welcome Area */}
                {/* <Welcome /> */}
                {/* About Area */}
                <Partner />
                {/* Strategy Area */}
                {/* <Strategy /> */}
                {/* Who We Are Area */}
                {/* <WhoWeAre /> */}
                {/* CTR Area */}
                {/* <Ctr /> */}
                {/* Skill Area */}
                {/* <Skill /> */}
                {/* Fact Area */}
                <Funfact />
                {/* Team Area */}
                {/* <Team /> */}
                {/* How We Work */}
                <HowWeWork />
                {/* Services Area */}
                {/* <Services /> */}
                {/* Why We Are Different */}
                {/* <WhyWeDifferent /> */}
                {/* Work Area */}
                {/* <Work /> */}
                {/* Price Area */}
                {/* <Price /> */}
                {/* FAQ Area */}
                <Faqs />
                {/* Testimonials Area */}
                <Testimonial />
                {/* Blog Area */}
                {/* <Blog /> */}
                {/* Partner Area */}
               
                {/* Subscribe Area */}
                {/* <Subscribe /> */}
                {/* Contact Area */}
                <Contact />
                {/* Footer Area */}
                <Footer />
                {/* Bottombar Area */}
                <Bottombar />
                <GoTop scrollStepInPx="50" delayInMs="16.66" />
            </React.Fragment>
        );
    }
}
 
export default HomeOne;