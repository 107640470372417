import React from 'react';
import { Link } from 'react-router-dom';
// import aboutImg from '../../assets/images/about.jpg';
import Footer from './Footer';
import Bottombar from './Bottombar';
class PrivacyPolicy extends React.Component {
    state = {
        isOpen: false
    }
componentDidMount() {
  window.scrollTo(0, 0)
}

    render() {
        return (
            <React.Fragment>
                <section id="about" className="about-area ptb-80">
                    {/* <section id="about" className="about-area ptb-80 bg-f6f6f6"></section> */}
                    <div className="container">
                        <div className="section-title">
                            <h2>Privacy <span>policy</span></h2>
                            <p></p>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-addax">
                                    <div className="section-title">
                                        <h3>Overview</h3>
                                    </div>
                                    <p>Thank you for visiting Autospares website. This is owned, operated and managed by New Age Vehicles Pvt. Ltd. (NAVPL).  Privacy is the unambiguous right of the individual and at NAVPL we have respect for your privacy.</p>
                                </div>
                            </div>
                        </div>
                        {/* break */}
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-addax">
                                    <div className="section-title">
                                        <h3>Purpose</h3>
                                    </div>
                                    <p>This Privacy Policy briefly describes the methodology of collection and usage of your data on the website. The objective of this Privacy Policy is to assure you on safe usage of our website(s) and safety of your data.</p>
                                </div>
                            </div>
                        </div>
                        {/* break */}
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-addax">
                                    <div className="section-title">
                                        <h3>Scope</h3>
                                    </div>
                                    <p>This Privacy Policy is applicable for all visitors to the website.</p>
                                    <p>Accessing, browsing or otherwise using the site indicates your agreement to the collection and usage of your data by NAVPL under the tenets of this Privacy Policy, so please read the Privacy Policy carefully before proceeding.</p>
                                    <p>If you disagree with the terms and conditions of this Privacy Policy, please refrain from using this website.</p>
                                    <p>The website may contain links to other web sites. NAVPL is not responsible for the privacy practices of such websites which it does not own, manage or control.</p>
                                    <p>The site and third-party vendors, including Google, use cookies together to inform, optimize, and serve ads based on a person’s past visits to the Site.</p>
                                    {/* <ul className="pull-left">
                                    <li><i className="fa fa-check"></i>Wide selection of auto spares parts</li>
                                    <li><i className="fa fa-check"></i>On-time delivery</li>
                                    <li><i className="fa fa-check"></i>Personal Accountability</li>
                                    <li><i className="fa fa-check"></i>Quality products with transparent pricing</li>
                                </ul>                              
                                <Link to="#" className="btn btn-primary">Read More</Link> */}
                                </div>
                            </div>
                            {/* <div className="col-lg-5 col-md-12">
                                <div className="about-video">
                                    <img src={aboutImg} alt="about" />
                                    <div className="video-btn">
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        {/* break */}
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-addax">
                                    <div className="section-title"> <h3>Policy</h3></div>
                                    <p>This Privacy Policy is effective from 01 January 2020.</p>
                                    <div className="col-lg-12 col-md-12">
                                        <h4 className="mtb-10">What information is, or may be, collected form you?</h4>
                                        <p>As part of the ‘Contact Us’ process on the website, NAVPL may collect the following personally identifiable information about you:</p>
                                        <ul>
                                            <li><i className="fa fa-check"></i>Name including first and last name,</li>
                                            <li><i className="fa fa-check"></i>Mailing address,</li>
                                            <li><i className="fa fa-check"></i>E-mail address,</li>
                                            <li><i className="fa fa-check"></i>Phone No,</li>
                                            <li><i className="fa fa-check"></i>Postal code, </li>
                                            <li><i className="fa fa-check"></i>Demographic profile and information about the pages on the website you visit, the number of times you access the page and any such browsing information. </li>
                                        </ul>
                                        <p>NAVPL will automatically receive and collect certain anonymous information in standard usage logs through our Web server, including computer-identification information obtained from "cookies," sent to your browser from:</p>
                                        <ul>
                                            <li><i className="fa fa-check"></i>web server cookie stored on your hard drive;</li>
                                            <li><i className="fa fa-check"></i>an IP address, assigned to the computer which you use;</li>
                                            <li><i className="fa fa-check"></i>the domain server through which you access our service;</li>
                                            <li><i className="fa fa-check"></i>the type of computer you're using.</li>
                                        </ul>
                                        <p></p>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <h4 className="mtb-10">How do we collect the Information?</h4>
                                        <p>NAVPL does not rent, sell or share your personal information and will not disclose any of your personally identifiable information to third parties. NAVPL may, however, share customer information on whole as a group with its partners or third parties where it deems necessary. NAVPL may use this information, to help investigate, prevent or take action regarding unlawful and illegal activities, suspected fraud, potential threat to the safety or security of any person, violations of the website’s terms of use or to defend against legal claims; special circumstances such as compliance with subpoenas, court orders, requests/order from legal authorities or law enforcement agencies requiring such disclosure.</p>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <h4 className="mtb-10">How is this information used?</h4>
                                        <p>NAVPL will use your personal information to provide personalized features to you on the website. NAVPL may use contact information internally to contact you as a survey respondent, to notify you on any promotions and to send newsletters. Information about Customers on whole as a group (excluding any personal identifiable information) covering Customer demographic and location data may be used for the purpose of creating additional features on the website and conducting marketing research and statistical analysis of customer behaviour.</p>
                                        <p>We use anonymous traffic information to:</p>
                                        <ul>
                                            <li><i className="fa fa-check"></i>remind us of who you are in order to deliver to you a better and more personalised service from both an advertising and an editorial perspective;</li>
                                            <li><i className="fa fa-check"></i>help diagnose problems with our server;</li>
                                            <li><i className="fa fa-check"></i>administer our Websites;</li>
                                            <li><i className="fa fa-check"></i>track your session so that we can understand better how people use our Website.</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <h4 className="mtb-10">With whom your information will be shared?</h4>
                                        <p>NAVPL does not rent, sell or share your personal information and will not disclose any of your personally identifiable information to third parties. NAVPL may, however, share customer information on whole as a group with its partners or third parties where it deems necessary. NAVPL may use this information, to help investigate, prevent or take action regarding unlawful and illegal activities, suspected fraud, potential threat to the safety or security of any person, violations of the website’s terms of use or to defend against legal claims; special circumstances such as compliance with subpoenas, court orders, requests/order from legal authorities or law enforcement agencies requiring such disclosure.</p>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <h4 className="mtb-10">What Choices are available to you regarding collection, use and distribution of your information?</h4>
                                        <p>NAVPL has implemented reasonable physical, electronic and administrative procedures to protect against the loss, misuse and alteration of the information under its control. Although NAVPL will endeavour to safeguard the confidentiality of your personally identifiable information, transmissions made by means of the Internet cannot be made absolutely secure. By using this site, you agree that NAVPL will have no liability for disclosure of your information due to errors in transmission or unauthorized acts of third parties.</p>
                                        <p>The company has implemented reasonable physical, technical and administrative measures to safeguard the information we collect in connection with the services. However, please note that although we take reasonable steps to protect your information, no website, Internet transmission, computer system or wireless connection is completely secure.</p>
                                        <p>Supplying personally identifiable information is entirely voluntary. You are not required to fill ‘Contact Us’ in order to use our Website. However, we offer some services only to visitors who do fill ‘Contact Us’.</p>
                                        <p>If you no longer wish to receive our newsletter and promotional communications, you may opt-out of receiving them by following the instructions included in each newsletter or communication or by emailing us at <Link to="" title="Read More" className="link-btn">sales@autosparesindia.in</Link>.</p>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <h4 className="mtb-10">How can you correct inaccuracies in the information?</h4>
                                        <p>To correct or update any information you have provided, you can send an e-mail to: <Link to="" title="Read More" className="link-btn">sales@autosparesindia.in</Link></p>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <h4 className="mtb-10">Consent and Governing Law</h4>
                                        <p>By using this Website, you consent to the terms of this Privacy Policy and to our use and management of Personal Information. </p>
                                        <p>Your visit and any dispute over privacy are subject to this Privacy Policy. Any dispute relating in any way to your visit to this website shall be submitted to the exclusive jurisdiction of the courts at Delhi, India only.</p>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <h4 className="mtb-10">Contact Us</h4>
                                        <p>For questions relating to privacy, we can be contacted at <Link to="" title="Read More" className="link-btn">sales@autosparesindia.in</Link>,</p>
                                        <p>Address: 204, 3rd Floor, Shahpur Jat, New Delhi – 110 049, India.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* break */}
                    </div>
                </section>
                <Footer />
                <Bottombar/>
            </React.Fragment>
        );
    }
}

export default PrivacyPolicy;