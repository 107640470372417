import React from 'react';
import { Link } from 'react-router-dom';
// import aboutImg from '../../assets/images/about.jpg';
import Footer from '../Common/Footer';
import Bottombar from './Bottombar';
class Tnc extends React.Component {
	state = {
		isOpen: false,
	};
	componentDidMount() {
		window.scrollTo(0, 0)
	  }
	render() {
		return (
			<React.Fragment>
				<section id="about" className="about-area ptb-80">
					{/* <section id="about" className="about-area ptb-80 bg-f6f6f6"></section> */}
					<div className="container">
						<div className="section-title">
							<h2>Terms and <span>condition</span></h2>
							<p></p>
						</div>

						{/* <div className="row">
							<div className="col-lg-12 col-md-12">
								<div className="about-addax">
									<div className="section-title">
										<h2>
											<span>Overview</span>
										</h2>
									</div>
									<div className="pull-left">
										<p>This website is owned, operated and managed by New Age Vehicles Pvt. Ltd. (NAVPL). All references to the term’s ‘company’, ‘firm’, ‘AutoSparesIndia’, ‘AutoSpares’ and ‘NAVPL’ refer to www.autosparesindia.com. Autospares always seeks to sell quality items at the best value. These items are of good quality, fit for purpose. The company is incorporated under the laws of India, having its registered office at 207, Suchet Chambers, 1224/5, Bank Street, Karol Bagh, New Delhi -110005. Subject to all the terms and conditions contained hereunder, the Website, including all information, tools and services available from this site, is offered to you for your fair usage.
</p>
									</div>

									<ul className="pull-left">
										<li>
											<i className="fa fa-check"></i>Wide selection of auto spares parts
										</li>
										<li>
											<i className="fa fa-check"></i>On-time delivery
										</li>
										<li>
											<i className="fa fa-check"></i>Personal Accountability
										</li>
										<li>
											<i className="fa fa-check"></i>Quality products with transparent pricing
										</li>
									</ul>							
								</div>
							</div>
							<div className="col-lg-6 col-md-12">
								<div className="about-video">
									<img src={aboutImg} alt="about" />
									<div className="video-btn">fxcvbxcvb</div>
								</div>
							</div>
						</div> */}
						<div className="row">
							<div className="col-lg-12 col-md-12">
								<div className="about-addax">
									<div className="section-title">
										<h3>
											<span>Overview</span>
										</h3>
									</div>
									<div className="pull-left">
										<p>This website is owned, operated and managed by New Age Vehicles Pvt. Ltd. (NAVPL). All references to the term’s ‘company’, ‘firm’, ‘AutoSparesIndia’, ‘AutoSpares’ and ‘NAVPL’ refer to www.autosparesindia.com. Autospares always seeks to sell quality items at the best value. These items are of good quality, fit for purpose. The company is incorporated under the laws of India, having its registered office at 207, Suchet Chambers, 1224/5, Bank Street, Karol Bagh, New Delhi -110005. Subject to all the terms and conditions contained hereunder, the Website, including all information, tools and services available from this site, is offered to you for your fair usage.</p>
									</div>
								</div>
							</div>
							<div className="col-lg-12 col-md-12">
								<div className="about-addax">
									<div className="section-title">
										<h3>
											<span>Purpose</span>
										</h3>
									</div>
									<div className="pull-left">
										<p>The Terms & Conditions given on this webpage shall govern your use of this website, including all pages within this website (collectively referred to herein below as this “Website”). These Terms apply in full force and effect to your use of this Website.</p>
									</div>
								</div>
							</div>
							<div className="col-lg-12 col-md-12">
								<div className="about-addax">
									<div className="section-title">
										<h3>
											<span>Scope</span>
										</h3>
									</div>
									<div className="pull-left">
										<p>These Terms & Conditions are applicable for all visitors, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content, to the website.</p>
										<p>Accessing, browsing or otherwise using this Website indicates your express agreement to all the terms and conditions contained herein in full.  Please read the Terms & Conditions carefully before proceeding.</p>
										<p>If you disagree with any of these terms and conditions, please refrain from using this website.	</p>
									</div>
								</div>
							</div>
							<div className="col-lg-12 col-md-12">
								<div className="about-addax">
									<div className="section-title">
										<h3>
											<span>Policy</span>
										</h3>
									</div>
									<div className="pull-left">
										<p>This Terms & Conditions is effective from 01 March 2020.</p>
										<h3 className="mtb-10">Modifications To These Terms Of Use </h3>
										<p>We reserve the right to change, modify, or update these Terms and conditions at any time without prior notice or obligation to user(s) and the restated terms and conditions shall be effective immediately on posting. It’s your responsibility to check our modified terms time to time. Your continuous use of the service will signify your acceptance of the changed terms and conditions.</p>
										<h3 className="mtb-10">Disclaimer And Warranties</h3>
										<p>This Website is provided “as is,” with all faults, and NAVPL makes no express or implied representations or warranties, of any kind related to this Website or the materials contained on this Website. Additionally, nothing contained on this Website shall be construed as providing consult or advice to you.</p>
										<p>In no event shall NAVPL , nor any of its officers, directors and employees, be liable to you for anything arising out of or in any way connected with your use of this Website, whether such liability is under contract, tort or otherwise, and NAVPL , including its officers, directors and employees shall not be liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.</p>
										<p>The Company makes no representation of any OEMs like HYUNDAI, TATA MOTORS, MAHINDRA AND MAHINDRA, MARUTI SUZUKI, FIAT, HONDA, HINDUSTAN MOTORS, TOYOTA, NISSAN, GENERAL MOTORS, AUDI, BMW, VOLVO, JAGUAR, LAND ROVER, MERCEDES and other Car Vehicle Manufacturer / car brands or any other and its associate in any manner.</p>
										<p>Website content has pictures; these pictures are representation of concept or service, not necessarily the original or true copy of product or service being sold.</p>
										<p>All the images and logos are owned by their respected companies/owners we are using these just for display purpose.</p>
										<p>All car images, logos used on the Website are the property of their respective owners. We do not hold any copyright regarding these pictures. These pictures have been collected from different public sources including various websites, considered to be in public domain. If anyone has any objection to display of any picture, image or text it may be brought to our notice by sending an email (Contact Us) and the disputed media will be removed immediately, after verification of the claim.</p>
										<h3>Personal Information</h3>
										<p>The submission of personal information through the Website is governed by our Privacy Policy. </p>
										<h4 className="mtb-10">Restrictions</h4>
										<p>You are expressly and emphatically restricted from all the following: </p>
										<ul className="pull-left">
											<li>
												<i className="fa fa-check"></i>publishing any Website material in any media;
										</li>
											<li>
												<i className="fa fa-check"></i>selling, sublicensing and/or otherwise commercializing any Website material;
										</li>
											<li>
												<i className="fa fa-check"></i>publicly performing and/or showing any Website material;
										</li>
											<li>
												<i className="fa fa-check"></i>using this Website in any way that is, or may be, damaging to this Website;
										</li>
											<li>
												<i className="fa fa-check"></i>using this Website in any way that impacts user access to this Website;
										</li>
											<li>
												<i className="fa fa-check"></i>using this Website contrary to applicable laws and regulations, or in a way that causes, or may cause, harm to the Website, or to any person or business entity;
										</li>
											<li>
												<i className="fa fa-check"></i>engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website, or while using this Website;
										</li>
											<li>
												<i className="fa fa-check"></i>using this Website to engage in any advertising or marketing;
											</li>
											<li>
												<i className="fa fa-check"></i>using this Website to infringe upon or violate our intellectual property rights or the intellectual property rights of others;
										</li>
											<li>
												<i className="fa fa-check"></i>using this Website to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;
												</li>
										</ul>
										<p>using this Website to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;</p>
										<h3 className="mtb-10">Indemnification</h3>
										<p>You hereby indemnify to the fullest extent NAVPL from and against any and all liabilities, costs, demands, causes of action, damages and expenses (including reasonable attorney’s fees) arising out of or in any way related to your breach of any of the provisions of these Terms.</p>
										<h3 className="mtb-10">Severability</h3>
										<p>If any provision of these Terms is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity shall not render these Terms unenforceable or invalid as a whole, and such provisions shall be deleted without affecting the remaining provisions herein.</p>
										<h3 className="mtb-10">Communication</h3>
										<p>When you use our mobile app or send emails or other data, information or communication to us, you agree and understand that you are communicating with us through electronic records and you consent to receive communications via electronic records from us when required. We may communicate with you by email or by such other mode of communication, which is possible.</p>
										<h3 className="mtb-10">Contact Information</h3>
										<p>Questions about the Terms of Service should be sent to us at <Link to="" title="Read More" className="link-btn">sales@autosparesindia.in</Link>.</p>
										<h3 className="mtb-10">Entire Agreement</h3>
										<p>These Terms, including any legal notices and disclaimers contained on this Website, constitute the entire agreement between NAVPL and you in relation to your use of this Website, and supersede all prior agreements and understandings with respect to the same.</p>
										<h3 className="mtb-10">Governing Law & Jurisdiction</h3>
										<p>These Terms & Conditions shall be governed by and construed in accordance with the laws New Delhi, India.</p>
										<p>Any dispute relating in any way to your visit to this Site or mobile app shall be submitted to the exclusive jurisdiction of the courts at Delhi, India only.</p>
									</div>
								</div>
							</div>

						</div>
					</div>
				</section>
				<Footer />
				<Bottombar/>
			</React.Fragment>
		);
	}
}

export default Tnc;
